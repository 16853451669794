













































































































































.validation-page {
  .logout-button {
    max-width: 200px;
    position: absolute;
    top: 50px;
    right: 50px;
  }
}
